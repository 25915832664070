<template>
  <div id="app">
    <GlobalErrorHandler :showError="showGlobalErrorHandler"></GlobalErrorHandler>
    <div v-if="hasLoaded && hasContext" class="container mx-auto mt-4 max-w-5xl mb-32 md:mb-40 px-6">
      <header class="clearfix">
        <HamburgerMenu v-if="availableLocales.length > 1">
          <template v-slot:config-links>
            <LanguageSelector
              :locale="translationLocale"
              :availableLocales="availableLocales"
              :setTranslation="setTranslation"
            ></LanguageSelector>
          </template>
        </HamburgerMenu>
        <div class="mt-2 xl:mt-12" :style="{ textAlign: 'center' }" v-if="this.theme !== 'anytime-fitness'">
          <a :href="'/' + hostname" v-if="isLogoUrl()">
            <img :src="getLogo()" alt="logo" class="mx-auto" />
          </a>
          <a :href="'/' + hostname" :style="{ fontSize: '30px', textDecoration: 'none' }" v-if="!isLogoUrl()">
            {{ getLogo() }}
          </a>
        </div>
        <div class="mt-2 xl:mt-12" v-if="this.theme === 'anytime-fitness'">
          <a :href="'/' + hostname">
            <img
              :src="require('@membr/component-library/src/themes/' + this.theme + '.svg')"
              alt="logo"
              class="mx-auto"
            />
          </a>
        </div>
      </header>
      <section class="mt-4 xl:mt-12">
        <router-view />
      </section>
    </div>
    <div v-if="hasLoaded && !hasContext" class="container mx-auto mt-4 xl:mt-10 max-w-5xl px-6">
      <h1>Unable to load gym</h1>
      <p>Check that the gym exists and online sign-up is enabled</p>
    </div>
  </div>
</template>

<script>
import { GlobalErrorHandler, HamburgerMenu, LanguageSelector, DateTimeUtils } from '@membr/component-library';
import { loadTheme } from '@/theme-loader';
import contextApi from '@/api/context-api';
import { UserCentrics } from './usercentrics';
import { loadGoogleAnalytics } from '@/google-analytics-loader';
import { Amplitude, EventType } from '@/amplitude';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    LanguageSelector,
    HamburgerMenu,
    GlobalErrorHandler
  },
  async created() {
    await this.setAppContext();
    const sessionId = this.$route.query['session-id'];
    this.$store.commit('setSignupIdentifier', sessionId || uuidv4());
  },
  data: function () {
    return {
      context: null,
      hasLoaded: false
    };
  },
  provide() {
    return {
      brandingProvider: () => ({
        primaryColour: this.context.branding.brandingPrimaryColour,
        secondaryColour: this.context.branding.brandingSecondaryColour
      })
    };
  },
  computed: {
    hasContext() {
      return this.context != null;
    },
    translationLocale() {
      return this.$route.query.locale ? this.$route.query.locale : this.context.locale;
    },
    availableLocales() {
      return this.context.availableLocales;
    },
    theme() {
      return this.context.theme;
    },
    hostname() {
      return this.$route.params.hostname;
    },
    showGlobalErrorHandler() {
      return this.$store.state.error != null;
    }
  },
  methods: {
    async setAppContext() {
      try {
        await this.initializeUsercentrics();
        if (this.hostname) {
          this.context = await contextApi.getContextFromHostname(this.hostname);
          this.$store.commit('setHostname', this.hostname);
        }

        if (this.$route.query.e2e) {
          window.localStorage.setItem('e2e', 1);
        }

        if (this.hasContext) {
          this.$store.commit('setContext', this.context);
          await this.setInternationalisation(this.translationLocale);
          await loadTheme(this.context.theme);
          await this.setupGoogleAnalytics(this.context.googleAnalyticsId);
          await this.initializeAmplitude(this.context.amplitude);
        }
      } finally {
        this.hasLoaded = true;
      }
    },
    async setInternationalisation(locale) {
      this.setDateTimeFormat(locale);
      await this.setTranslation(locale);
    },
    async setDateTimeFormat(locale) {
      DateTimeUtils.setLocale(locale);
      this.$store.commit('setDateFormat', DateTimeUtils.getDateFormat());
    },
    async setTranslation(locale) {
      await this.loadTranslation(locale);
      this.$i18n.locale = locale;
      this.$store.commit('updateLocale', locale);
      this.setDateTimeFormat(locale);
    },
    async loadTranslation(locale) {
      const messages = await import(/* webpackChunkName: "locale-[request]" */ `@/locales/${locale}`);
      this.$i18n.setLocaleMessage(locale, messages.default);
    },
    async initializeUsercentrics() {
      UserCentrics.initialize();
    },
    async setupGoogleAnalytics(googleAnalyticsId) {
      if (!googleAnalyticsId) {
        return;
      }
      await loadGoogleAnalytics(googleAnalyticsId);
      //now google analytics has loaded, fire an event for this pageview
      this.$gtag.pageview({
        page_title: this.$route.name,
        page_path: this.$route.path,
        page_location: window.location.href
      });
    },
    async initializeAmplitude(amplitudeConfig) {
      Amplitude.initialize(amplitudeConfig.track, amplitudeConfig.apiKey);

      if (this.$route.name === 'packages') {
        await Amplitude.raiseEvent(EventType.SignupInitiated);
      }
    },
    getLogo() {
      return this.context.branding.logo;
    },
    isLogoUrl() {
      return this.context.branding.isLogoUrl;
    }
  }
};
</script>
