<template>
  <section :style="{ color: brandingSecondaryColour }">
    <h1 class="text-xl xl:text-4xl">{{ $t('membershipPackages.heading') }}</h1>
    <p v-if="showAnytimeFitnessPromotion" class="text-lg mt-2 font-light anytime-fitness-promotion">
      {{ $t('temporaryAnytimeFitnessPromotion') }}
    </p>
    <p v-else class="text-lg mt-1 xl:mt-2 font-light tagLine">
      {{ $t('membershipPackages.tagLine', { gymName }) }}
    </p>
    <p
      v-if="signupPromotionsHeaderText"
      v-html="signupPromotionsHeaderText"
      class="text-lg mt-1 xl:mt-2 font-light tagLine"
    ></p>

    <div class="xl:border-t xl:theme-border-color xl:mt-10">
      <div v-if="isLoading" class="mt-8">
        <Loader :title="$t('membershipPackages.loading')" />
      </div>
      <p
        class="no-membership-packages mt-4"
        v-if="
          !isLoading &&
          !publicMembershipPackages.length &&
          !promoMembershipPackages.length &&
          !referralMembershipPackages.length
        "
      >
        {{ $t('membershipPackages.noPackages') }}
      </p>

      <div class="pt-3 pb-6 border-b" v-if="!isLoading && promoMembershipPackages.length">
        <h2 class="text-xl my-2">
          {{ $t('membershipPackages.promotionalPackages') }}
        </h2>
        <div class="flex flex-wrap justify-center -mx-6 gap-4 xl:gap-8">
          <div
            class="w-full md:w-1/2 lg:w-1/3 px-2 lg:px-4 flex"
            v-for="promoMembershipPackage in promoMembershipPackages"
            :key="promoMembershipPackage.packageId"
          >
            <MembershipPackage
              :membershipPackage="promoMembershipPackage"
              @package-selected="selectPackage"
              :data-cy="getPackageDataCy('promo', promoMembershipPackage)"
              :multiSiteAccessEnabled="getMultiSiteAccessSetting"
            />
          </div>
        </div>
      </div>

      <div class="pt-3 pb-6 border-b" v-if="!isLoading && referralMembershipPackages.length">
        <h2 class="text-xl my-2">
          {{ $t('membershipPackages.referralPackages') }}
        </h2>
        <div class="flex flex-wrap justify-center -mx-6">
          <div
            class="w-full md:w-1/2 lg:w-1/3 px-2 lg:px-4 flex"
            v-for="referralMembershipPackage in referralMembershipPackages"
            :key="referralMembershipPackage.packageId"
          >
            <MembershipPackage
              :membershipPackage="referralMembershipPackage"
              @package-selected="selectPackage"
              :data-cy="getPackageDataCy('referral', referralMembershipPackage)"
              :multiSiteAccessEnabled="getMultiSiteAccessSetting"
            />
          </div>
        </div>
      </div>
      <div v-if="!isLoading">
        <div class="flex flex-wrap justify-center -mx-6">
          <div
            class="w-full md:w-1/2 lg:w-1/3 px-2 lg:px-4 flex"
            v-for="membershipPackage in publicMembershipPackages"
            :key="membershipPackage.packageId"
          >
            <MembershipPackage
              :membershipPackage="membershipPackage"
              @package-selected="selectPackage"
              :data-cy="getPackageDataCy('membership', membershipPackage)"
              :multiSiteAccessEnabled="getMultiSiteAccessSetting"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="clearfix mt-6 fixed bottom-0 left-0 theme-alternate-bg theme-alternate-text w-full py-2 md:px-8 px-4"
      :style="{ background: isMpayClub ? '#e2e8f0' : brandingPrimaryColour }"
    >
      <div class="container mx-auto max-w-5xl h-12 lg:h-16 xl:h-24">
        <SpecialPackagesLoader
          @promo-packages-loaded="promoPackagesLoaded"
          @referral-packages-loaded="referralPackagesLoaded"
          @failed-to-load="failedToLoadSpecialPackages"
          :promoCodeInitial="promoCodeInitial"
          :referralCodeInitial="referralCodeInitial"
        />
      </div>
    </div>
  </section>
</template>

<script>
import membershipPackageApi from '@/api/membership-package-api';
import SpecialPackagesLoader from '@/components/membership-packages/SpecialPackagesLoader.vue';
import { Loader, MembershipPackage } from '@membr/component-library';
import { Amplitude, EventType } from '@/amplitude';
import rejoinPoller from '@/rejoin/rejoin-poller.js';
import Analytics from '@/api/analytics';

export default {
  name: 'MembershipPackages',
  components: {
    Loader,
    SpecialPackagesLoader,
    MembershipPackage
  },
  async created() {
    await this.getPublicPackages();
    this.setRejoinProperties();
    if (!this.$store.state.isCookieSet) {
      this.setCookie(this.createWebDropOffTime);
    }
    if (!this.selectedPackage) {
      this.getAnalyticsData();
    }
  },
  data() {
    return {
      publicMembershipPackages: [],
      promoMembershipPackages: [],
      referralMembershipPackages: [],
      isLoadingPublicPackages: true,
      isLoadingSpecialPackages: Boolean(this.promoCodeInitial) || Boolean(this.referralCodeInitial),
      specialCodeType: null,
      member: {
        name: {
          firstName: null,
          firstNamePhonetic: null,
          lastName: null,
          lastNamePhonetic: null
        },
        idNumber: null,
        placeOfBirth: null,
        phone: null,
        dateOfBirth: {
          day: '',
          month: '',
          year: ''
        },
        gender: '',
        address: {
          address1: null,
          address2: null,
          town: null,
          county: null,
          postcode: null,
          country: null
        },
        email: '',
        password: {
          newPassword: '',
          repeatPassword: ''
        },
        recommendation: '',
        marketing: false,
        termsAndConditions: false,
        additionalTermsAndConditions: false,
        extraTermsAndConditions: false,
        emergencyContact: {
          name: null,
          relation: null,
          phone: null
        },
        registrationCookie: ''
      }
    };
  },
  props: {
    promoCodeInitial: { type: String, required: false },
    referralCodeInitial: { type: String, required: false },
    packageSlug: { type: String, required: false }
  },
  computed: {
    selectedPackage: {
      get() {
        return this.$store.state.membershipPackage;
      },
      set(value) {
        this.$store.commit('setMembershipPackage', value);
      }
    },
    gymId() {
      return this.$store.state.context.gymId;
    },
    gymName() {
      return this.$store.state.context.gymName;
    },
    isLoading() {
      return this.isLoadingPublicPackages || this.isLoadingSpecialPackages;
    },
    showAnytimeFitnessPromotion() {
      return this.$store.state.context.locale === 'nl_NL' && this.$store.state.context.theme === 'anytime-fitness';
    },
    createWebDropOffTime() {
      return this.$store.state.context.createWebDropOffTime;
    },
    signupPromotionsHeaderText() {
      return this.$store.state.context.signupPromotionsHeaderText;
    },
    branding() {
      return this.$store.state.context.branding;
    },
    brandingPrimaryColour() {
      return this.$store.state.context.branding.brandingPrimaryColour;
    },
    brandingSecondaryColour() {
      return this.$store.state.context.branding.brandingSecondaryColour;
    },
    isMpayClub() {
      return this.$store.state.context.isMpayCompany;
    },
    getMultiSiteAccessSetting() {
      return this.$store.state.context.multiAccessSetting;
    }
  },
  methods: {
    randomString(x) {
      var s = '';
      while (s.length < x && x > 0) {
        var r = Math.random();
        s += r < 0.1 ? Math.floor(r * 100) : String.fromCharCode(Math.floor(r * 26) + (r > 0.5 ? 97 : 65));
      }
      return s;
    },

    setCookie(time) {
      if (!this.member.registrationCookie || this.member.registrationCookie === '') {
        this.member.registrationCookie = this.randomString(128);
      }
      var date = new Date();
      date.setTime(date.getTime() + time * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'registrationId=' + this.member.registrationCookie + expires + '; path=/';
      this.$store.commit('setRegistrationCookie', this.member.registrationCookie);
    },

    async getPublicPackages() {
      this.publicMembershipPackages = await membershipPackageApi.getMembershipPackages(this.gymId);
      this.isLoadingPublicPackages = false;

      if (this.packageSlug && this.publicMembershipPackages.length > 0) {
        const slugPackage = this.publicMembershipPackages.find(pkg => pkg.slug === this.packageSlug);
        if (slugPackage) {
          this.selectPackage(slugPackage);
        }
      }
    },
    setRejoinProperties() {
      if (typeof this.$route.params !== 'undefined' && typeof this.$route.params.generatedHash !== 'undefined') {
        this.$store.commit('setHash', this.$route.params.generatedHash);
        this.$store.commit('setMpayRejoin', true);
        this.getUserAndTokenFromHash();
      }
    },
    isSelected(membershipPackage) {
      if (!membershipPackage || !this.selectedPackage) {
        return false;
      }

      return membershipPackage.packageId === this.selectedPackage.packageId;
    },
    async selectPackage(membershipPackage) {
      this.selectedPackage = membershipPackage;

      await Amplitude.raiseEvent(EventType.PackageSelected, { 'package-name': membershipPackage.name });

      const eventProperties = {
        name: membershipPackage.name,
        count:
          this.publicMembershipPackages.length +
          this.referralMembershipPackages.length +
          this.promoMembershipPackages.length,
        status: membershipPackage.status
      };

      if (membershipPackage.charge) {
        if (membershipPackage.packageType === 'pif') {
          eventProperties.joining_fee = membershipPackage.charge.amount;
        } else {
          eventProperties.ongoing_charge = membershipPackage.charge.amount;
          if (membershipPackage.joiningFee) {
            eventProperties.joining_fee = membershipPackage.joiningFee.amount;
          }
        }
      }

      Analytics.trackEvent(Analytics.events.packageSelected, eventProperties);

      this.next();
    },
    promoPackagesLoaded(packages) {
      Analytics.trackEvent(Analytics.events.promoCodeEntered, {
        added_packages_count: packages.length
      });
      this.promoMembershipPackages = packages;
      this.isLoadingSpecialPackages = false;
    },
    referralPackagesLoaded(packages) {
      Analytics.trackEvent(Analytics.events.referralCodeEntered, {
        added_packages_count: packages.length
      });
      this.referralMembershipPackages = packages;
      this.isLoadingSpecialPackages = false;
    },
    failedToLoadSpecialPackages() {
      this.isLoadingSpecialPackages = false;
    },
    getUserAndTokenFromHash() {
      if (this.$store.state.isMpayRejoin) {
        rejoinPoller.pollStatus(
          this.$store.state.hash,
          this.$store.state.context.gymId,
          this.userAndTokenFromHashCallback,
          this.pollingInterval
        );
      }
    },
    userAndTokenFromHashCallback(user, token) {
      this.$store.commit('setToken', token);

      this.member.name.firstName = user.firstName;
      this.member.name.lastName = user.lastName;
      this.member.email = user.email;
      this.member.phone = user.phone;

      this.member.dateOfBirth.day = user.dayOfBirth;
      this.member.dateOfBirth.month = user.monthOfBirth;
      this.member.dateOfBirth.year = user.yearOfBirth;

      this.member.gender = user.gender;

      this.member.address.address1 = user.address1;
      this.member.address.address2 = user.address2;
      this.member.address.town = user.town;
      this.member.address.county = user.county;
      this.member.address.postcode = user.postcode;
      this.member.address.country = user.country;

      this.member.termsAndConditions = false;
      this.member.additionalTermsAndConditions = false;
      this.member.extraTermsAndConditions = false;

      this.$store.commit('setMember', this.member);
    },
    next() {
      if (this.$route.name !== 'packages') {
        // looks like Vue is re-rendering the component when the slug is in query params
        // and we this.$router.push, so what is happening this function was executed once for /packages list
        // and later when user already is on /personal-details, to prevent that we have
        // we have to execute this logic when user is on /packages page
        return;
      }

      if (this.$store.state.isMpayRejoin) {
        this.$store.commit('setMember', this.member);
        this.$router.push({ name: 'personalDetails' });
      } else {
        this.$router.push({ path: 'personal-details' });
      }
    },
    getPackageDataCy(prefix, selectedPackage) {
      return `${prefix}-package-${selectedPackage.packageId}`;
    },
    getAnalyticsData() {
      Analytics.trackEvent(Analytics.events.packagesAvailable, { count: this.publicMembershipPackages.length });
    }
  }
};
</script>
