export default {
  map(context) {
    return {
      amplitude: {
        track: context.amplitude && context.amplitude.track,
        ...(context?.amplitude?.api_key && { apiKey: context.amplitude.api_key }),
        ...(context?.amplitude?.environment && { environment: context.amplitude.environment })
      },
      hostId: context.host_id,
      gymId: context.gym_id,
      locale: context.locale,
      currencyCode: context.currency_code,
      countryCode: context.country_code,
      availableLocales: context.available_locales,
      branding: {
        brandingPrimaryColour: context.branding_primary_colour,
        brandingSecondaryColour: context.branding_secondary_colour,
        logo: context.logo,
        isLogoUrl: context.is_logo_url
      },
      theme: context.theme,
      gymName: context.name,
      multiAccessSetting: context.is_global_access_multi_site_access,
      enableWebDropOffs: context.is_web_drop_offs_enabled,
      createWebDropOffTime: context.web_drop_off_time,
      email: context.email,
      phone: context.phone,
      googleAnalyticsId: context.google_analytics_id,
      startDate: {
        minimum: context.start_date.minimum_date,
        maximum: context.start_date.maximum_date,
        days: context.start_date.days
      },
      signupFooterText: context.signup_footer_text,
      signupPromotionsHeaderText: context.signup_promotions_header_text,
      signupSettings: mapSignupSettingsFields(context.signup_settings),
      website: context.website,
      memberSignup: {
        agreementFields: mapAgreementFields(context.preset.agreement_fields),
        names: context.member_signup.names,
        address: {
          format: context.member_signup.address_format,
          type: context.member_signup.address_type,
          alternativeFormats: context.member_signup.alternative_address_formats
        },
        shouldUppercasePostcode: context.preset.should_uppercase_postcode,
        characterCompatibilityValidationEnabled: context.preset.enable_character_compatibility_validation,
        loginUrl: context.member_signup.login_url,
        requireIdNumber: context.member_signup.require_id_number,
        requireIdExpiryDate: context.country_code === 'AE',
        requirePlaceOfBirth: context.member_signup.require_place_of_birth,
        requireCompanyNumber: context.member_signup.require_company_number,
        requireTermsAndConditions: context.member_signup.require_terms_and_conditions,
        requireEmergencyContact: context.member_signup.require_emergency_contact,
        passwordPolicy: context.member_signup.password_policy,
        phoneValidation: context.member_signup.phone_validation,
        showOneOffPaymentsSummaryOnMembershipSetupPage:
          context.preset.show_one_off_payments_summary_on_membership_setup_page,
        showOneOffPaymentsSummaryOnPaymentPage: context.preset.show_one_off_payments_summary_on_payment_page,
        showDueNowPaymentsSummaryOnMembershipSetupPage:
          context.preset.show_due_now_payments_summary_on_membership_setup_page,
        showMemberLoginButtonOnSuccessPage: context.preset.show_member_login_button_on_success,
        useAtfLegalTerms: context.preset.use_atf_legal_terms,
        bankAgreementUrl: context.preset.agreement_fields.bank_agreement_url,
        privacyNoticeUrl: context.preset.agreement_fields.privacy_notice_url,
        useDdmsImmediatePayment: context.preset.use_ddms_immediate_payment,
        terms: {
          text: context.member_signup.terms.terms,
          url: context.member_signup.terms.url
        },
        privacyUrl: context.member_signup.privacy_url,
        additionalTerms: context.member_signup.additional_terms,
        extraTermsAggregated: mapTerms(context.preset.terms_and_conditions_documents),
        enableParqQuestionOnSignup: context.preset.enable_parq_question_on_signup
          ? context.preset.enable_parq_question_on_signup
          : false,
        shouldUseSeparateConsent: context.preset.should_use_separate_consent,
        fixedBillingDate: context.member_signup.fixed_billing_date,
        agreementLogoUrl: context.preset.agreement_logo_url
      },
      isAtfCompany: context.is_atf_club,
      isMpayCompany: context.is_mpay_club,
      isAE: context.country_code === 'AE'
    };
  }
};

function mapTerms(terms) {
  if (!terms) {
    return null;
  }

  const typeMap = {
    terms_and_conditions: 'termsAndConditions',
    conditions_for_admission: 'conditionsForAdmission',
    cardio_notice: 'cardioNotice',
    tanning_notice: 'tanningNotice',
    personal_info_notice: 'personalInfoNotice'
  };

  return terms.map(term => {
    return {
      type: typeMap[term.type],
      url: term.url
    };
  });
}

function mapSignupSettingsFields(signupSettings) {
  return {
    idUploader: {
      isVisible: typeof signupSettings === 'undefined' ? true : signupSettings?.id_uploader?.is_visible,
      isMandatory: typeof signupSettings === 'undefined' ? false : signupSettings?.id_uploader?.is_mandatory
    },
    profilePhotoUploader: {
      isVisible: typeof signupSettings === 'undefined' ? true : signupSettings?.profile_photo_uploader?.is_visible,
      isMandatory: typeof signupSettings === 'undefined' ? false : signupSettings?.profile_photo_uploader?.is_mandatory
    }
  };
}

function mapAgreementFields(agreementFields) {
  if (!agreementFields) {
    return null;
  }

  return {
    gymDetails: agreementFields.gym_details,
    personalDetails: agreementFields.personal_details,
    membershipDetails: agreementFields.membership_details,
    bankAgreementUrl: agreementFields.bank_agreement_url,
    privacyNoticeUrl: agreementFields.privacy_notice_url
  };
}
